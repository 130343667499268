:root {
  --primary-color: #FF784B;
  --secondary-color: #002335;
}


.btn-secondary{
  background-color: var(--secondary-color)!important;
  color: white;
}

.btn-primary,
.btn-primary .text-secondary,
.btn-primary .fi{
  background-color: var(--primary-color)!important;
  color: white!important;
}

.btn-outline-primary{
  color: var(--primary-color)!important;
  border: 2px solid var(--primary-color)!important;
}

.outline-primary{
  border: 2px solid var(--primary-color)!important;
}

.outline-light{
  border: 2px solid #ffffff!important;
}

.outline-secondary{
  border: 2px solid var(--secondary-color)!important;
}


.btn-light{
  background-color: #ffffff;
  box-shadow: 0px 4px 6px rgba(0,0,0,0.2);
  color: var(--secondary-color)!important;
}

.active-border{
  border: 2px solid var(--primary-color)!important;
}

.ico-disabled{
  opacity: 0.5;
}

.ico-primary{
  color: var(--primary-color);
}

.ico-secondary{
  color: var(--secondary-color);
}

.ico-light{
  color: #ffffff;
}

.circle{
  border-radius: 50%!important;
}

.underline-primary {
  text-decoration: underline;
  text-decoration-color: var(--primary-color)!important;
  text-decoration-thickness: 4px;
  text-underline-offset: 3px;
}


.btn-primary .underline-primary {
  text-decoration-color: var(--secondary-color)!important;
}


.form-check-input:checked {
  background-color: var(--primary-color)!important;
  border-color:var(--primary-color)!important;
}
.form-check-input:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 120, 75, 0.5); /* Orange glow on focus */
}

.bg-primary {
  background-color: var(--primary-color)!important;
}

.bg-light {
  background-color: #ffffff!important;
}

.dropdown-item:focus {
  background-color: var(--primary-color)!important;
}

.fs-7 {
  font-size: 0.875rem; /* 10px */
}

.progress-circle {
  width: 120px; /* Size of the circle */
  height: 120px;
  border-radius: 50%; /* Makes it circular */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: conic-gradient(var(--primary-color) 0%, #e9ecef 0); /* Default gradient */
}

.progress-circle-inner {
  width: 90px; /* Inner circle */
  height: 90px;
  border-radius: 50%;
  background-color: white; /* Inner background */
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}


.inactive {
  opacity: 0.6; /* Lower opacity for a faded look */
}

