h1, h2, h3, h4, h5, h6 {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
}

body {
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: small;
}

.top, .center, .bottom {
  width: 100%!important;
}

.center {
  display: flex;
  justify-content: center;
}


.active .fi{
  color: var(--primary-color)!important;
}

.fi {
  font-size:medium;
}


.image-caption {
  position: absolute;
  bottom: 0;
  color: white;
  background-color: #0023354D;
  width: 100%;
  border-radius: 10px; /* Adjust the value as needed */
}

.image-top {
  text-align: center;
  position: absolute;
  top: -0.5rem;
  color: white;
  background-color: #002335;
  border-radius: 10px; /* Adjust the value as needed */
}


.link-active {
  color: var(--primary-color)!important;
  text-decoration: none;
} 


.link {
  color:inherit;
  text-decoration: none;
}
